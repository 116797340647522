// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-pages-bassin-page-js": () => import("./../src/templates/pages/bassin-page.js" /* webpackChunkName: "component---src-templates-pages-bassin-page-js" */),
  "component---src-templates-pages-business-page-js": () => import("./../src/templates/pages/business-page.js" /* webpackChunkName: "component---src-templates-pages-business-page-js" */),
  "component---src-templates-pages-chambres-page-js": () => import("./../src/templates/pages/chambres-page.js" /* webpackChunkName: "component---src-templates-pages-chambres-page-js" */),
  "component---src-templates-pages-contact-page-js": () => import("./../src/templates/pages/contact-page.js" /* webpackChunkName: "component---src-templates-pages-contact-page-js" */),
  "component---src-templates-pages-home-page-js": () => import("./../src/templates/pages/home-page.js" /* webpackChunkName: "component---src-templates-pages-home-page-js" */),
  "component---src-templates-pages-mentions-page-js": () => import("./../src/templates/pages/mentions-page.js" /* webpackChunkName: "component---src-templates-pages-mentions-page-js" */),
  "component---src-templates-pages-tboutique-page-js": () => import("./../src/templates/pages/tboutique-page.js" /* webpackChunkName: "component---src-templates-pages-tboutique-page-js" */)
}

